/*  */
.Ground-Shipping-Img p {
  font-size: 16px; /* Ensure font size is set correctly */
  line-height: 1.5;
  color: #7A7A7A; /* Adjust color as needed */
  margin-top: 0;
  margin-bottom: 1rem; /* Adds space after each paragraph */
  font-family: "Montserrat", sans-serif; /* Ensure font family is applied */
}

.Ground-Shipping-Img p {
  font-size: 16px;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #7A7A7A;
  margin-top: 0;
  font-family: "Monserrat, sans-serif";
}

.Ground-Shipping-Img ul {
  font-size: 16px; /* Match font size with paragraphs */
  line-height: 1.5;
  color: #7A7A7A; /* Match color with paragraphs */
  font-family: "Montserrat", sans-serif; /* Match font family with paragraphs */
  margin-top: 0;
  margin-bottom: 1rem; /* Space after list */
  padding-left: 1.5rem; /* Add some padding for list items */
}

/* List items */
.Ground-Shipping-Img ul li {
  margin-bottom: 0.5rem; /* Space between list items */
}