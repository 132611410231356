.footer {
  /* background-color: #f8f9fa; */

  margin-top: 5rem;
}

.Footer-contain {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem; /* Added for spacing */
}

.footer-div {
  flex: 1;
  min-width: 220px;
}

.footer h4 {
  color: #444444;
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}

.footer ul {
  padding: 0;
  list-style-type: none;
}

.footer ul li {
  margin-bottom: 0.5rem;
  color: #444444;
  font-size: 18px;
}

.footer ul li a {
  color: #444444;
  text-decoration: none;
  font-size: 16px;
  font-family: "Montserrat", Sans-serif;
}

.footer ul li a:hover {
  color: #007bff;
  text-decoration: underline;
}

.footer .btn {
  background-color: #3e5e9a;
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
}

.footer .btn i {
  margin-left: 0.5rem;
}

.footer-bottom {
  background-color: #343a40;
  color: white;
  padding: 1rem 0;
}

.footer-bottom .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom p {
  margin: 0;
}

.social-icons a {
  color: white;
  margin-left: 1rem;
  font-size: 1.5rem;
}

/* Responsive Styles */
@media (max-width: 767.98px) {
  .row {
    flex-direction: column;
  }
  .footer-div {
    width: 100%;
    /* margin-bottom: 1rem; */
  }
}

/* Ensuring the "Download Brochure" button aligns correctly */
.download-brochure {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns the button to the left */
}

.download-brochure .btn {
  margin-top: 0.5rem; /* Adjust as needed */
}

@media (max-width: 768px){

  .download-brochure {
  display: flex;
  align-items: center;
  }

}