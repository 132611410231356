
.logistics_section {
  position: relative; 
  background-image: radial-gradient(at bottom right, #3e5e9a 35%, #4586c0 100%);
  overflow: hidden;
 
}

.logistics_section .elementor-shape svg {
  width: calc(374% + 161.3px);
  height: 10rem;
  transform: translateX(-43%) rotateY(117deg);
}

.logistics_section .elementor-shape svg .elementor-shape-fill {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  height: 30rem;
}

.text-content {
  max-width: 50%;
  color: white;
  max-width: 50%;
  position: relative;
  color: white;
  /* top: -90px; */
  bottom: 200px;
}

.text-content h4 {
  color: #ffffff;
  font-family: "Varela Round", Sans-serif;
  font-size: 45px;
  font-weight: 300;
  text-transform: capitalize;
  line-height: 1.2em;
}

.text-content p {
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
}

.text-content .btn {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  fill: #ffffff;
  color: #ffffff;
  background-color: #f37266;
  border-radius: 30px 30px 30px 30px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  width: 130px;
  height: 40px;
  cursor: pointer;
}

.logistics_image {
  height: 40rem;
  max-width: 45%;
  border-radius: 30px 170px 170px 30px;
  position: relative;
  top: -170px;
  top: -140px;
}
.carouselpadding_change{
  padding: 60px;
}

/* new css */
.mobile-view-home-M2{
  color: rgb(255, 255, 255);
         font-family: "Valera Round", sans-serif;
         font-size: 40px;
         font-weight: 700;
         text-transform: capitalize;
         mix-blend-mode: luminosity;
}


/* Mobile css */

@media (max-width: 768px){
  .mobile-view-home-M2{
    color: rgb(255, 255, 255);
    font-family: "Valera Round", sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    mix-blend-mode: luminosity;
    margin-bottom: 7px;
  }
  .Home-page-first-video{
    height: 67pc !important;
  }
  .home-page-cardbody-M{
    padding: 0px !important;
  }
  .home-page-card-body2-M{
    padding: 0px !important;
  }
  .steps-For-Shipment-M{
    color: rgb(62, 94, 154);
    font-size: 36px !important;
    font-weight: 500 !important;
    text-transform: capitalize;
    line-height: 1.2rem;
  }
  .Reason-to-choose-M{
    color: rgb(62, 94, 154);
    font-size: 33px !important;
    font-weight: 500 !important;
    text-transform: capitalize;
    line-height: 1.2rem;
    margin-bottom: 4rem !important;
  }
  .industries-We-Serve-M{
    color: rgb(62, 94, 154);
    font-size: 37px !important;
    font-weight: 500 !important;
    text-transform: capitalize;
    line-height: 1.2rem;
    margin-bottom: -1em !important;
  }
  .carouselpadding_change-M .carousel-slider{
    width: 100% !important;
  }
  .slider-every-img{
    width: 12px !important;
  }
  .carouselpadding_change-M{
    padding: 0;
  }
  .founder-we-server-start-M{
    padding: 1rem !important;
    
  }
  .faisal-Islam-M{
margin-bottom: 15px;
  }
  .our-Preferre-Dairlines-M{
    color: rgb(62, 94, 154);
    font-size: 31px !important;
    font-weight: 500 !important;
    text-transform: capitalize;
    line-height: 1.2rem;
    margin-bottom: -3rem !important;

  }
  .our-preferred-airlines-M{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: 44rem;
    flex-direction: column;
  }
  .our-preferred-airlines-M .text-content {
    max-width: 72%;
    position: relative;
    color: white;
    /* top: -90px; */
    bottom: 78px;
}
.our-preferred-airlines-M .text-content h4 {
  color: #ffffff;
  font-family: "Varela Round", Sans-serif;
  font-size: 21px;
  font-weight: 300;
  text-transform: capitalize;
  line-height: 1.2em;
}
.logistics_image{
  height: 15rem;
    max-width: 100%;
    border-radius: 30px 170px 170px 30px;
    position: relative;
    top: -258px;
}
.logistics_section {
  position: relative;
  background-image: radial-gradient(at bottom right, #3e5e9a 35%, #4586c0 100%);
  overflow: hidden;
  height: 41pc;
}
}