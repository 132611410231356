/* General styles remain the same */

.header_container {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #1964f1;
  padding: 0 20px;
}

.header .logo-container {
   flex: 1;   /*Allows the logo container to take up necessary space */
  display: flex;
  align-items: center;
}

.header .logo {
  max-width: 100%;
  height: auto;
}

.nav-list-container {
  flex: 2; /* Allows the nav list to take up the remaining space */
  display: flex;
  justify-content: center;
}

.nav-list {
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  list-style: none;
  color: white;
  font-weight: bold;
  width: 100%;
}

.nav-list a {
  color: aliceblue;
  text-decoration: none; /* Ensure links have no underline */
  font-size: 15px;
}

.nav-list li {
  position: relative;
  padding: 10px;
  white-space: nowrap; /* Prevent line breaks */
  font-family: "Varela Round", Sans-serif;
}

.nav-list li:hover {
  border-bottom: 4px solid white;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  color: black;
  padding: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: none; /* Initially hide the dropdown */
  flex-direction: column;
  min-width: 160px;
}

.dropdown:hover .dropdown-menu {
  display: flex; /* Show dropdown on hover */
}

.dropdown-menu li {
  padding: 8px 16px;
}

.dropdown-menu li a {
  color: rgb(68, 68, 82); /* Set the link color to blue */
  text-decoration: none; /* Remove underline from links */
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

/* Responsive Design */

@media (max-width: 768px) {
  .header {
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .nav-list-container {
    width: 100%;
  }

  .nav-list {
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .nav-list li {
    padding: 10px 0;
    text-align: center;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    display: none; /* Hide dropdown menu by default on mobile */
    width: 100%; /* Full width dropdown */
  }

  .dropdown-menu.active {
    display: flex; /* Show dropdown menu when active */
  }
}

/* Optional: Style dropdown menu for mobile click event */
@media (max-width: 768px) {
  .dropdown {
    cursor: pointer;
  }
}
