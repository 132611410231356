.work-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    padding: 9rem 0 0 0 ;
}

.work-with-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../Assets/BG.png");
    background-size: cover;
    width: 100%;
    margin-bottom: 5rem;
    padding: 7rem;
    box-sizing: border-box;
}

.work-heading h1 {
    color: #3E5E9A;
    font-family: "Varela Round", Sans-serif;
    font-size: 45px;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 1.2em;
}

.work-with-us-container h2

{
    color: #3E5E9A;
    font-family: "Varela Round", Sans-serif;
    font-size: 40px;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 1.2em;
}

.work-heading h3 {
    color: #3d4459;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 300;
    margin-top: 1rem;
}

.work-with-us-inner-container-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
}

.work-with-us-inner-container {
    height: 18rem;
    width: 20rem;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 15px 15px 15px 80px;
    text-align: center;
    padding: 2rem 1rem 1rem 1rem;
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.work-with-us-inner-container h3 {
    color: #3d4459;
    font-family: "Varela Round", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
}

.work-with-us-inner-container img {
    height: 4rem;
    width: 5rem;
    margin-bottom: 2rem;
}

.work-with-us-inner-container p {
    color: #3d4459;
    font-family: "Montserrat", Sans-serif;
    font-size: 16px;
    font-weight: 300;
}
.workwithus-homep{
    background-color: #1a5ede;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .work-with-us-inner-container {
        height: auto;
        padding: 4rem 1rem 1rem 1rem;
        width: calc(33.33% - 2rem);
    }
}

@media (max-width: 768px) {
    .work-with-us-container-M{
        padding: 2rem !important
    }
    .work-with-us-inner-container {
        width: calc(50% - 2rem);
    }

    .work-heading h1 {
        font-size: 36px;
    }

    .work-heading h3 {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .work-with-us-inner-container {
        width: calc(100% - 2rem);
    }

    .work-heading h1 {
        font-size: 28px;
    }

    .work-heading h3 {
        font-size: 18px;
    }
}
