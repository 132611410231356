/* New css */
.our-services-mukul-div .our-services-mukul h1{
  color: rgb(255, 255, 255);
  font-family: "Varela Round", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.2em;
  font-size: 45px;
}
.our-services-mukul-div .our-services-mukul p{
  color: rgb(255, 255, 255);
  font-family: "Varela Round", Sans-serif;
  font-size: 20px;
  font-weight: 300;
  text-transform: capitalize;
}
/* .ourservices-section .our-services_new{
  padding: 0 307px !important;
} */
.ourservices-section .our-services_new .section-our-services_new{
  z-index: -2 !important;
}

/* new css */

.about-us-image{
    background-image: url("../../Assets/Aamir-islam.jpeg");
}

.about_us_parent_container .elementor-shape svg {
    width: calc(374% + 161.3px);
    /* height: 10rem; */
    z-index: -1;
    margin-bottom: -2rem;
    transform: translateX(-43%) rotateY(117deg);
  }
  
  .about_us_parent_container .elementor-shape svg .elementor-shape-fill {
    fill: #fff;
    transform-origin: center;
    transform: rotateY(0deg);
  }

  .about_us_parent_container .elementor-shape {
    transform: rotate(180deg);
  }

  .about_us_parent_container {
    background-color: #3939df;
    /* height: 538px; */
    z-index: 3;
    overflow: hidden;

    filter: brightness(100%) contrast(100%) saturate(200%) blur(0px) hue-rotate(360deg)
  }



  .centered_text {
    position: relative;
    z-index: 2;
    color: #fff;
    max-width: 80%;
    margin: 0 auto;
  }



  .centered_text p
  {
    color: #ffffff;
    font-family: "Montserrat", Sans-serif;
    font-size: 20px;
    font-weight: 300;
  }

  .about_us_parent_container .about_us_home_img{
    height: 503px;
    opacity: 0.3;
    z-index: 1;
    background-position: center left;
  }

  .container-About{
background-image: url("../../Assets/BG.png");

    margin-top: 1rem;
  }



.about-us-box{

   

  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 2rem;
  background-image: url("../../Assets/BG-TEAN-Home.png"); 

}



  /* gap: 10px;

  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 2rem;
  background-image: url("../../Assets/BG-TEAN-Home.png"); */



  .col-sm h3 {

    color: #3D4459;
    font-family: "Montserrat", Sans-serif;
    font-size: 20px;
    font-weight: 300;
    text-transform: capitalize;
    text-align: justify;
  }

  .col-sm h1 {
    color: #3E5E9A;
    font-family: "Varela Round", Sans-serif;
    font-size: 45px;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 1.2em;
    text-align: justify;
  }

  

  .col-sm p {
    color: #3E5E9A;
    font-family: "Varela Round", Sans-serif;
    font-size: 16px;
   word-spacing: 4px;
    font-weight: 200;
    text-transform: capitalize;
    line-height: 1.2em;
    text-align: -webkit-left;
  }



  .container-About-right {
   margin-top: 5rem;
   overflow: hidden;
  }

  
  .container-About-right img{
    margin-right: 2rem;
  }


  .container-About-right{
  padding:   30px 130px 52px 40px;

  }

  .about-us-box{

    height: 25rem;
    background-color: #ffffff;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    border-radius: 10px 10px 10px 55px;
    height: 30rem;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px 10px 10px 55px;
    margin-left: 1rem;
    margin-right: 1rem;
  }


  .track-order-container{

  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 5rem;
  gap: 1rem;


  }

  /* src/Services.css */
  .Our-Services {
    gap: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
    margin-top: 2rem;
    background-image: url("../../Assets/BG-TEAN-Home.png");
    flex-wrap: wrap; /* Ensure it wraps on smaller screens */
  }
  
  .ser {
    height: 30rem;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
    border-radius: 10px 10px 10px 55px;
    margin: 1rem;
    padding: 1rem;
  }

.ser-card .icon {
  margin-bottom: 15px;
}

.ser-card .icon img {
  width: 50px;
}

.ser-card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.ser-card .card-text {
  color: #6c757d;
}

.my-5{

  background-image: url("../../Assets/BG.png");
}


.box-about{

  height: 300px; /* Adjust height as needed */
  width: 100%; 
  border-radius: 10px 20px 20px 90px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 50, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem; /* Add some spacing between rows if needed */
}

.left-margin {
  margin-left: 15px; /* Adjust left margin as needed */
}

.reduce-gap {
  margin-right: 10px; /* Adjust horizontal gap as needed */
}




.about-box {
  display: flex;
  gap: 5rem;
  justify-content: space-around;
  margin-left: 1rem;
  margin-top: 3rem;
  background-image: url("../../Assets/BG-TEAN-Home.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-box-inner {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the image horizontally */
  justify-content: center; /* Centers the image vertically */
  height: auto;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 50, 0.2);
  border-radius: 10px 20px 20px 90px;
  padding: 1rem; /* Add padding to ensure content isn't touching edges */
}

.about-box-no {
  flex-direction: column;
  height: auto;
  width: 100%;
}

.about-box-inner img {
  height: 5rem;
  width: 5rem;
  margin-bottom: 20px;
  margin-top: 20px;
}

.about-box-inner h2 {
  color: #3E5E9A;
  font-family: "Varela Round", Sans-serif;
  font-size: 20px;
  font-weight: 300;
  text-transform: capitalize;
  line-height: 1.2em;
}

.about-box-inner p {
  color: #3D4459;
  font-size: 16px;
  margin: 0 0 20px; /* Adjusted margin for better spacing */
  font-family: Montserrat, sans-serif;
  padding: 0 1rem; /* Added padding for better spacing on smaller screens */
}

.about-box-text {
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: left;
  padding: 1rem; /* Added padding */
}

.about-box-text h3 {
  font-size: 20px;
  color: #444444;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
}

.about-box-text h1 {
  font-size: 45px;
  font-weight: 400;
  color: #3E5E9A;
  font-family: "Varela Round", Sans-serif;
  text-transform: capitalize;
  line-height: 1.2em;
}

.about-box-text p {
  font-family: Montserrat, sans-serif;
}



/* 
.aford {
  margin: 4rem 0rem 2rem 2rem;
  background-image: url("../../Assets/BG-TEAN-Home.png");
width: 60%;
padding: 40px 20px 20px 70px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 50, 0.2);
border-radius: 10px 20px 20px 90px;

}

.aford img{
  height: 5rem;
  width: auto;

}

.aford h3
{
  color: #3E5E9A;
  font-family: "Varela Round", Sans-serif;
  font-size: 20px;
  font-weight: 300;
  text-transform: capitalize;
  line-height: 1.2em;
  text-align: justify;
  padding: 40px 0px 0px 20px;
}



.aford-text{


  justify-content: center;
  padding: px 0px 0px 20px
  
}
.aford-text p

{
  text-align: justify;
  font-family: "Varela Round", Sans-serif;
  color: #3D4459;}
  padding: 30px; */
  /* font-size: 16px; */

/* Mobile Styles (max-width: 600px) */
.aford {
  margin: 4rem 0rem 2rem 2rem;
  background-image: url("../../Assets/BG-TEAN-Home.png");
  width: 60%;
  padding: 40px 20px 20px 70px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 50, 0.2);
  border-radius: 10px 20px 20px 90px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.aford img {
  height: 5rem;
  width: auto;
}

.aford h3 {
  color: #3E5E9A;
  font-family: "Varela Round", Sans-serif;
  font-size: 20px;
  font-weight: 300;
  text-transform: capitalize;
  line-height: 1.2em;
  text-align: justify;
  padding: 40px 0px 0px 20px;
}

.aford-text {
  justify-content: center;
  padding: 0px 0px 0px 20px;
}

.aford-text p {
  text-align: justify;
  font-family: "Varela Round", Sans-serif;
  color: #3D4459;
  padding: 30px;
}


.inline-text h1{

color: white;
}

@media (max-width: 600px) {
  .about-box {
    flex-direction: column; /* Stack items vertically */
    gap: 2rem;
    margin-left: 0;
    margin-top: 1rem;
  }

  .about-box-inner img {
    height: 4rem;
    width: 4rem;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .about-box-inner h2 {
    font-size: 18px;
  }

  .about-box-inner p {
    font-size: 14px;
    margin: 0 0 15px;
  }

  .about-box-text h3 {
    font-size: 18px;
  }

  .about-box-text h1 {
    font-size: 36px;
  }

  .about-box-text p {
    font-size: 14px;
  }
  .aford {
    padding: 15px 5px 5px 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .aford h3 {
    font-size: 12px;
  }

  .aford-text p {
    font-size: 14px;
  }
}
















/* Tablet Styles (min-width: 601px and max-width: 900px) */
@media (min-width: 601px) and (max-width: 900px) {
  .about-box {
    flex-direction: column; /* Stack items vertically or adjust as needed */
    gap: 3rem;
    margin-left: 0;
    margin-top: 2rem;
  }

  .about-box-inner img {
    height: 4.5rem;
    width: 4.5rem;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .about-box-inner h2 {
    font-size: 19px;
  }

  .about-box-inner p {
    font-size: 15px;
    margin: 0 0 20px;
  }

  .about-box-text h3 {
    font-size: 19px;
  }

  .about-box-text h1 {
    font-size: 40px;
  }

  .about-box-text p {
    font-size: 15px;
  }
}




@media (max-width: 1200px) {
  .aford {
    width: 80%;
    padding: 30px 15px 15px 60px;
  }

  .aford h3 {
    font-size: 18px;
    padding: 30px 0px 0px 15px;
  }

  .aford-text p {
    padding: 20px;
  }
}


/* Mobile Css */
@media (max-width: 768px){
.our-services_new-M{
padding: 0 6px !important;
}
.our-services_new-section-tow-M{
  padding: 0 6px !important;
}
.our-services_new-section-three-M{
  padding: 0 6px !important;
}
.our-services_new-section-four-M{
  padding: 0 6px !important;
}
.our-services_new-section-five-M{
  padding: 0 6px !important;
}
.our-services_new-section-six-M{
  padding: 0 6px !important;
}
.our-services_new-section-seven-M{
  padding: 0 6px !important;
}
.oceanfreight-an-M{
  margin-left: -18pc !important;
}
}