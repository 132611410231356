/* GroundShippingBlog.css */

/* Base styles for the blog container */
.Ground-Shipping-Blog-div {
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    padding: 1rem; /* Adds padding to ensure content doesn’t touch the edges */
    text-align: center; /* Centers text inside the container */
    padding: 5rem
  }
  
  /* Styles for the image and content container */
  .Ground-Shipping-Img {
    width: 80%;
    text-align: left; 
  }
  
  /* Responsive image styles */
  .Ground-Shipping-Img img {
    border: 0;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    padding: 20px;
    width: 100%; /* Ensure image scales correctly */
  }
  
  /* Main heading styles */
  .Ground-Shipping-Img h1 {
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 1.3;
    margin-bottom: 0.9231em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
  
  /* Styles for span elements */
  .Ground-Shipping-Img span {
    font-size: 20px;
    font-weight: 700;
    font-size: 10px;
    margin-top: 2rem; /* Space above the heading */
    margin-bottom: 2rem; /* Space below the heading */
    text-align: left; /* Align text to the left */
    color: #7A7A7A;
    font-family: "Monserrat, sans-serif";
  }
  
  /* Styles for paragraphs */
  .Ground-Shipping-Img-div p {
    font-size: 16px;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #7A7A7A;
    margin-top: 0;
    font-family: "Monserrat, sans-serif";
   
    
  }
  .Ground-shiping-img-div{
    text-align: -webkit-center;
  }
  .Ground-shipaing-all-content{
    padding: 6rem 11rem 0 12rem;
    text-align: justify;
  }
  .Ground-shipaing-all-content h1{
font-size: 26px;
  }
  .Ground-shipaing-all-content p{
    font-family:  "Montserrat", sans-serif;
    font-size: 15px;
  }
  /* Media queries for responsiveness */
  
  /* Mobile screens */
  @media (max-width: 600px) {
    .Ground-shipaing-all-content {
      padding: 4rem 1rem 0 1rem;
      text-align: justify;
  }
  .Ground-Shipping-Blog-div {
    display: flex
;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
}
    .Ground-Shipping-Img {
      width: 100%; /* Full width on small screens */
      text-align: left; /* Align text to the left on small screens */
    }
    
    .Ground-Shipping-Img img {
      width: 100%; /* Ensure image scales to full width */
      padding: 10px; /* Adjust padding for smaller screens */
    }
  
    .Ground-Shipping-Img h1 {
      font-size: 20px;
      font-size: 2rem;
    }
  
    .Ground-Shipping-Img span {
      font-size: 18px;
      font-size: 1.8rem;
    }
  
    .Ground-Shipping-Img p {
      font-size: 14px;
      font-size: 1.4rem;
    }
  }
  
  /* Tablet screens */
  @media (min-width: 601px) and (max-width: 1024px) {
    .Ground-Shipping-Img {
      width: 90%; /* Slightly narrower on tablets */
    }
  
    .Ground-Shipping-Img h1 {
      font-size: 24px;
      font-size: 2.4rem;
    }
  
    .Ground-Shipping-Img span {
      font-size: 19px;
      font-size: 1.9rem;
    }
  
    .Ground-Shipping-Img p {
      font-size: 16px;
      font-size: 1.5rem;
    }
  }
  
  /* Larger screens */
  @media (min-width: 1025px) {
    .Ground-Shipping-Img {
      width: 80%; /* Default width for larger screens */
    }
  
    .Ground-Shipping-Img h1 {
      font-size: 26px;
      font-size: 2.6rem;
    }
  
    .Ground-Shipping-Img span {
      font-size: 20px;
      font-size: 20px;
      ;
    }
  
    .Ground-Shipping-Img p {
      font-size: 16px;
      font-size: 1rem;
    }
  }
  