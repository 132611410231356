.trade-data {
    border: 1px solid #7A7A7A;
    width: 100%;
    text-align: center;
    margin-bottom: 4rem;
}

.trade-data td {
    border: 1px solid #7A7A7A;
    padding: 10px;
    color: #7A7A7A;
}

.trade-data th {
    border: 1px solid #7A7A7A;
    color: #7A7A7A;
}

.Ground-Shipping-Img ol {
    font-size: 16px; /* Match font size with paragraphs */
    line-height: 1.5;
    color: #7A7A7A; /* Match color with paragraphs */
    font-family: "Montserrat", sans-serif; /* Match font family with paragraphs */
    margin-top: 0;
    margin-bottom: 1rem; /* Space after list */
    padding-left: 1.5rem; /* Add some padding for list items */
}

.Ground-Shipping-Img ol li {
    margin-bottom: 1rem; /* Adjust this value to increase or decrease the gap */
}
