.FAQ-container{
    background-image: url("../../Assets/BG.png");


}

.curved-bottom {
    width: 100%;
    height: 30rem;
    background: rgb(69, 40, 236);
    /* border-bottom-left-radius: 50% 50px;
    border-bottom-right-radius: 50% 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .Faq-content {
    z-index: 1;
    color: white;
    font-size: 20px;
    text-align: center;
  }
  .Faq-content h1

  {
    color: #ffffff;
    font-family: "Varela Round", Sans-serif;
    font-size: 60px;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 1.2em;
  }
  .Faq-content h3

  {color: #ffffff;
    font-family: "Montserrat", Sans-serif;
    font-size: 20px;
    font-weight: 300;
    text-transform: capitalize;}


.accordion {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    
  }
  
  .accordion h2 {
    color: #3E5E9A;
    font-family: "Varela Round", Sans-serif;
    font-size: 45px;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 1.2em;
  }
  
  .accordion p {
    color: #3d4459;
    font-family: "Montserrat", Sans-serif;
    font-size: 20px;
    font-weight: 300;
    text-transform: capitalize;
  }
  
  .accordion-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .accordion-item {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .accordion-title {
    font-family: "Montserrat", Sans-serif;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    color: #3E5E9A;
  }
  
  .accordion-title:hover {
    background: #f9f9f9;
  }
  
  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding: 0 15px;
  }
  
  .accordion-content.show {
    max-height: 200px; /* Adjust based on content height */
    padding: 15px;
  }
  
  .arrow {
    border: solid #3a68ad;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
  }
  
  .arrow.down {
    transform: rotate(45deg);
  }
  
  .arrow.up {
    transform: rotate(-135deg);
  }
  
  @media (max-width: 768px) {
    .accordion-grid {
      grid-template-columns: 1fr;
    }
  }
  .accordion {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .accordion h2 {
    text-align: center;
    color: #3a68ad;
  }
  
  .accordion p {
    text-align: center;
    color: #7b7b7b;
    margin-bottom: 20px;
  }
  
  .accordion-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .accordion-item {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .accordion-title {
    padding: 15px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-title:hover {
    background: #f9f9f9;
  }
  
  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding: 0 15px;
  }
  
  .accordion-content.show {
    max-height: 200px; /* Adjust based on content height */
    padding: 15px;
  }
  
  .arrow {
    border: solid #3a68ad;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
  }
  
  .arrow.down {
    transform: rotate(45deg);
  }
  
  .arrow.up {
    transform: rotate(-135deg);
  }

  /* FAQs.css */
.services_header_section {
  position: relative;
  overflow: hidden;
}

.wave {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px; /* Adjust height if needed */
  overflow: hidden;
}

.wave svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.accordion {
  padding: 2rem;
}

.accordion-item {
  margin-bottom: 1rem;
}

.accordion-title {
  cursor: pointer;
  font-weight: bold;
}

.accordion-content {
  display: none;
  padding: 1rem;
}

.accordion-content.show {
  display: block;
}

.arrow {
  transition: transform 0.3s;
}

.arrow.up {
  transform: rotate(180deg);
}

  
  @media (max-width: 768px) {
    .accordion-grid {
      grid-template-columns: 1fr;
    }
  }
    