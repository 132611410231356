.News-div-container {
    display: flex; /* Enables flexbox */
    justify-content: space-around; /* Distributes space around the items */
    align-items: center; /* Aligns items vertically in the center */
    padding: 71px 173px 0; /* Adds some padding */
    gap: 20px; /* Adds space between the items */
  }
  
  .news-box {
    display: flex;
    flex-direction: column; /* Stacks children vertically */
    align-items: center; /* Centers children horizontally */
    flex: 1; /* Allows the boxes to grow and take equal space */
    /* background-color: lightgrey; Example background color */
    padding: 20px; /* Adds some padding inside each box */
    text-align: center; /* Centers the text inside the box */
    border: 1px solid #ccc; /* Adds a border */
    margin: 10px; /* Adds some margin around each box */
    box-shadow: 5px 10px #f3f1f1;
    row-gap:2rem;
  }


  .news-box p


{
    font-family: "Montserrat", Sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.news-box button
{
    font-family: "Montserrat", Sans-serif;
    border: none;
    background-color: none;
    color: blue;
}

.news-box h3


{
    font-family: "Montserrat", Sans-serif;
    font-size: 18px;
    font-weight: 400;
}

  
  
  .news-box img {
   width: 100%;
    height: auto;
    margin-bottom: 10px; /* Adds some space below the image */
  }
  
  .news-box a {
    color: blue;
    text-decoration: none;
    color: #3E5E9A;
    font-family: "Montserrat", Sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
  
  .news-box a:hover {
    text-decoration: underline; /* Adds underline on hover */
  }

  .newsblogs-homep{
    background-color: #1a5ede;
  }

  /* Mobile css */
  @media (max-width: 768px){

  .News-div-container-M{
    padding: 0 !important;
  }
}